exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-config-js": () => import("./../../../src/pages/config.js" /* webpackChunkName: "component---src-pages-config-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-documentation-js": () => import("./../../../src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-access-js": () => import("./../../../src/pages/en/access.js" /* webpackChunkName: "component---src-pages-en-access-js" */),
  "component---src-pages-en-config-js": () => import("./../../../src/pages/en/config.js" /* webpackChunkName: "component---src-pages-en-config-js" */),
  "component---src-pages-en-contacts-js": () => import("./../../../src/pages/en/contacts.js" /* webpackChunkName: "component---src-pages-en-contacts-js" */),
  "component---src-pages-en-documentation-js": () => import("./../../../src/pages/en/documentation.js" /* webpackChunkName: "component---src-pages-en-documentation-js" */),
  "component---src-pages-en-download-js": () => import("./../../../src/pages/en/download.js" /* webpackChunkName: "component---src-pages-en-download-js" */),
  "component---src-pages-en-gather-js": () => import("./../../../src/pages/en/gather.js" /* webpackChunkName: "component---src-pages-en-gather-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-install-js": () => import("./../../../src/pages/en/install.js" /* webpackChunkName: "component---src-pages-en-install-js" */),
  "component---src-pages-en-instructions-js": () => import("./../../../src/pages/en/instructions.js" /* webpackChunkName: "component---src-pages-en-instructions-js" */),
  "component---src-pages-en-pipe-js": () => import("./../../../src/pages/en/pipe.js" /* webpackChunkName: "component---src-pages-en-pipe-js" */),
  "component---src-pages-en-plugins-js": () => import("./../../../src/pages/en/plugins.js" /* webpackChunkName: "component---src-pages-en-plugins-js" */),
  "component---src-pages-en-pluginsdk-js": () => import("./../../../src/pages/en/pluginsdk.js" /* webpackChunkName: "component---src-pages-en-pluginsdk-js" */),
  "component---src-pages-en-sink-js": () => import("./../../../src/pages/en/sink.js" /* webpackChunkName: "component---src-pages-en-sink-js" */),
  "component---src-pages-en-versions-js": () => import("./../../../src/pages/en/versions.js" /* webpackChunkName: "component---src-pages-en-versions-js" */),
  "component---src-pages-en-view-js": () => import("./../../../src/pages/en/view.js" /* webpackChunkName: "component---src-pages-en-view-js" */),
  "component---src-pages-en-virgin-js": () => import("./../../../src/pages/en/virgin.js" /* webpackChunkName: "component---src-pages-en-virgin-js" */),
  "component---src-pages-gather-js": () => import("./../../../src/pages/gather.js" /* webpackChunkName: "component---src-pages-gather-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-js": () => import("./../../../src/pages/install.js" /* webpackChunkName: "component---src-pages-install-js" */),
  "component---src-pages-instructions-js": () => import("./../../../src/pages/instructions.js" /* webpackChunkName: "component---src-pages-instructions-js" */),
  "component---src-pages-pipe-js": () => import("./../../../src/pages/pipe.js" /* webpackChunkName: "component---src-pages-pipe-js" */),
  "component---src-pages-plugins-js": () => import("./../../../src/pages/plugins.js" /* webpackChunkName: "component---src-pages-plugins-js" */),
  "component---src-pages-pluginsdk-js": () => import("./../../../src/pages/pluginsdk.js" /* webpackChunkName: "component---src-pages-pluginsdk-js" */),
  "component---src-pages-sink-js": () => import("./../../../src/pages/sink.js" /* webpackChunkName: "component---src-pages-sink-js" */),
  "component---src-pages-versions-js": () => import("./../../../src/pages/versions.js" /* webpackChunkName: "component---src-pages-versions-js" */),
  "component---src-pages-view-js": () => import("./../../../src/pages/view.js" /* webpackChunkName: "component---src-pages-view-js" */),
  "component---src-pages-virgin-js": () => import("./../../../src/pages/virgin.js" /* webpackChunkName: "component---src-pages-virgin-js" */)
}

